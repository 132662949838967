<template>
  <router-link :to="{ name: 'Overview', params: { id: widget.id } }">
    <div class="widget-item" @click="handleClick">
      <div class="widget-details">
        <div class="widget-type">
          <i class="fas fa-comments" title="Feedback Widget"></i>
        </div>
        <div class="widget-title">
          <h4>{{ widget.details.name }}</h4>
          <h6><i>Domain: {{ widget.details.domain }}</i></h6>
          <h6><i>Created: {{ widget.createdAt }} ago</i></h6>
        </div>
        <div v-if="!isLoading && widget.details.active" class="widget-button"><i class="fas fa-pause-circle" title="Pause Widget" @click="(e) => handleActivity(e, widget.id, widget.details.active)"></i></div>
        <div v-if="!isLoading && !widget.details.active" class="widget-button"><i class="fas fa-play-circle" title="Activate Widget" @click="(e) => handleActivity(e, widget.id, widget.details.active)"></i></div>
        <div v-if="isLoading" class="widget-button disabled"><i class="fas fa-spinner" title="Loading"></i></div>
      </div>
      <div class="widget-stats">
        <div class="reaction all">
          <h4 title="All Ratings">{{ widget.stats.totalVotes }}</h4>
        </div>
        <div v-for="emoji in widget.emojis" :key="emoji.value" class="reaction">
          <img :src="emoji.emojiURL" class="emoji">
          <h4>{{ emoji.votes }}</h4>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import useFetch from '../../composables/useFetch'
import { Toast } from '../../composables/getGlobalVariables'

export default {
  props: ['widget'],
  setup() {
    const { patchData, isLoading, error } = useFetch()

    const handleActivity = async (e, widgetId, isActive) => {
      e.preventDefault()
      const text = (isActive) ? 'Widget paused successfully' : 'Widget activated successfully'

      await patchData(`/widgets/${widgetId}`, { 
        'details.active': !isActive
      })

      if (!error.value) {
        Toast.fire({
          title: 'Success!',
          text: text,
          icon: 'success'
        })
      } else {
        Toast.fire({
          title: 'Oops...',
          text: error.value,
          icon: 'error'
        })
      }
    }

    return { handleActivity, isLoading }
  }
}
</script>

<style>
  .widget-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 0 auto 30px auto;
    padding: 20px 20px 20px 30px;
	  background-color: var(--border);
	  border: 1px solid var(--border);
	  border-radius: 15px;
	  cursor: pointer;
    box-shadow: 0 2px 4px 3px rgba(0, 0, 0, 0.2);
    transition: all ease 0.2s;
  }
  .widget-item:hover {
    transform: scale(1.01);
    transition: all ease 0.2s;
    box-shadow: 0 6px 15px 5px rgba(0, 0, 0, 0.2);
  }
  .widget-type {
    margin-right: 50px;
    padding-top: 10px;
  }
  .widget-type i {
    font-size: 40px;
    color: var(--main);
  }
  .widget-details {
    width: 50%;
    display: flex;
    justify-content: left;
  }
  .widget-button {
    margin: 15px 60px 0 20px;
  }
  .widget-button i {
    cursor: pointer;
    font-size: 30px;
    color: var(--main);
  }
  .widget-button i:hover {
    color: var(--main-hover);
  }
  .widget-button.disabled i {
    cursor: not-allowed;
    overflow: hidden;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .widget-title {
    width: 100%;
  }
  .widget-title h6 {
    margin-top: 5px;
    font-weight: 500;
  }
  .widget-stats {
    width: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .widget-stats .reaction {
    text-align: center;
    margin: 0 25px;
  }
  .widget-stats .reaction h4 {
    font-size: 16px;
    font-weight: 700;
  }
  .widget-stats .reaction.all {
    margin: 0 40px 0 25px;
  }
  .widget-stats .reaction.all h4 {
    font-size: 30px;
    cursor: help;
  }
</style>