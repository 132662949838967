<template>
  <div v-if="profile" class="page-header">
    <h3>Your Widgets</h3>
    <button v-if="profile.billing.status !== 'active' || (profile.billing.plan === 'free-plan' && formattedWidgets.filter(el => el.details.userId == profile.id).length >= 2)" disabled title="Only available on active paid subscriptions, or free plans with less than 2 created widgets" tabindex="0">Create New Widget</button>
    <router-link v-else :to="{ name: 'Create' }" class="btn">Create New Widget</router-link>
  </div>
  <div class="container">
    <div v-if="route.params.isNew || route.params.isNewLTD" class="inner-container">
      <div class="waiting new">
        <h1>Welcome to Emojise 🎉</h1>
        <h4 v-if="profile.billing.plan == 'lifetime'">And thanks for purchasing our Lifetime Deal from SaaS Mantra!</h4>
        <img src="../assets/images/welcome.webp">
        <h3>Create your first widget and start collecting user feedback right away ❤️</h3>
        <router-link :to="{ name: 'Create' }" class="btn">Create First Widget</router-link>
      </div>
    </div>
    <div v-else class="inner-container main">
      <div class="tab">
        <div class="tablink" :class="{ active: (curTab == 'created') }" @click="curTab = 'created'">Created Widgets</div>
        <div class="tablink" :class="{ active: (curTab == 'shared') }" @click="curTab = 'shared'">Shared Widgets</div>
      </div>
      <div v-if="!formattedWidgets || !formattedWidgets.length" class="waiting">
          <img src="../assets/images/waiting.gif">
          <h3 v-if="curTab == 'created'">Waiting for you to create your first widget...</h3>
          <h3 v-if="curTab == 'shared'">Waiting for someone to share a widget with you..</h3>
          <router-link :to="{ name: 'Create' }" class="btn">Create First Widget</router-link>
      </div>
      <WidgetItem v-for="widget in formattedWidgets" :key="widget.id" :widget="widget" />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import getUser from '../composables/getUser'
import getCollection from '../composables/getCollection'
import WidgetItem from '../components/Widget/WidgetItem.vue'
import { formatDistanceToNow } from 'date-fns'

export default {
  components: { WidgetItem },
  setup() {
    const route = useRoute()
    const { user } = getUser()
    const { profile, widgets } = getCollection()
    const curTab = ref('created')

    const formattedWidgets = computed(() => {
      if (widgets.value) {
        if (curTab.value === 'created') {
          const createdWidgets = widgets.value.filter(el => el.details.userId === user.value.uid)
          return createdWidgets.map(el => {
            let time = formatDistanceToNow(el.details.createdAt.toDate())
            return { ...el, createdAt: time }
          })
        } else {
          const sharedWidgets = widgets.value.filter(el => el.details.userId !== user.value.uid)
          return sharedWidgets.map(el => {
            let time = formatDistanceToNow(el.details.createdAt.toDate())
            return { ...el, createdAt: time }
          })
        }
      } else {
        return []
      }
    })

    return { route, profile, formattedWidgets, curTab }
  }
}
</script>

<style>
  .inner-container.main {
    padding: 0;
  }
  .waiting {
    text-align: center;
  }
  .waiting img {
	  margin: 30px auto;
	  max-width: 350px;
    border-radius: 10px;
  }
  .waiting.new img {
    max-width: 350px;
  }
  .wating h1 {
    margin-top: 10px;
  }
  .waiting h3 {
    margin-bottom: 30px;
  }
  .waiting a {
    margin-bottom: 40px;
  }
</style>